<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <el-icon>
            <menu />
          </el-icon>
          WeeklyReports
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <div class="handle-box">
        <el-select
          v-model="query.grade"
          placeholder="Grade"
          class="mr10"
          :clearable="true"
        >
          <el-option
            v-for="item in options.gradeList"
            :key="item.value"
            :label="item.description"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-select v-model="query.term" placeholder="Term" class="mr10" :clearable="true">
          <el-option
            v-for="item in options.termList"
            :key="item.value"
            :label="item.description"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-input
          v-model="query.name"
          placeholder="Report Name"
          class="handle-input mr10"
          @keyup.enter="handleSearch"
          :clearable="true"
        ></el-input>
        <el-button type="primary" @click="handleSearch">
          <el-icon>
            <search />
          </el-icon>
          Search
        </el-button>
      </div>
      <el-table
        :data="tableData"
        border
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
        @sort-change="changeSort"
        :default-sort="{ prop: 'UpdatedTime', order: 'descending' }"
      >
        <el-table-column
          prop="weeklyReport.name"
          label="Report Name"
          sortable="true"
          :sort-orders="['ascending', 'descending', null]"
          :sort-by="Name"
        ></el-table-column>
        <el-table-column
          prop="weeklyReport.roomName"
          label="Room Name"
          :width="this.$widthRatio * 240 + 'px'"
        ></el-table-column>
        <el-table-column
          prop="weeklyReport.grade"
          label="Grade"
          :width="this.$widthRatio * 80 + 'px'"
          sortable="true"
          :sort-orders="['ascending', 'descending', null]"
          :sort-by="Grade"
        ></el-table-column>
        <el-table-column
          prop="weeklyReport.term"
          label="Term"
          :width="this.$widthRatio * 80 + 'px'"
        ></el-table-column>
        <el-table-column label="Status" :width="this.$widthRatio * 120 + 'px'">
          <template #default="scope">
            <el-tag
              class="ml-2"
              :type="showWeeklyReportStatusTagType(scope.$index, scope.row)"
            >
              {{ scope.row.weeklyReport.status }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="weeklyReport.updatedTime"
          sortable="true"
          :sort-orders="['ascending', 'descending', null]"
          :sort-by="UpdatedTime"
          label="Updated Time"
          :width="this.$widthRatio * 120 + 'px'"
          :formatter="$tableCellFormatter.formatDate"
        ></el-table-column>
        <el-table-column
          label="Operation"
          :width="this.$widthRatio * 200 + 'px'"
          align="center"
        >
          <template #default="scope">
            <el-button
              type="text"
              @click="showWeeklyReport(scope.$index, scope.row, 'quiz')"
            >
              <el-icon><histogram /></el-icon>
              Quiz Report
            </el-button>
            <el-button
              type="text"
              @click="showWeeklyReport(scope.$index, scope.row, 'homework')"
            >
              <el-icon><histogram /></el-icon>
              Homework Report
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-dialog
        ref="weeklyRecordReportDialog"
        :title="weeklyRecordReportDialogTitle"
        v-model="showWeeklyRecordReportDialog"
        :destroy-on-close="true"
        width="90%"
      >
        <weekly-report-chart
          :isForAdmin="false"
          :reportId="currentReport.reportId"
          :recordType="this.reportRecordType"
          :destroy-on-close="true"
        />
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { getData } from "../../service/api";
import WeeklyReportChart from "../common/WeeklyReportChart.vue";

export default {
  components: { WeeklyReportChart },
  name: "weeklyReportList",
  data() {
    return {
      controllerUrl: "/weeklyReport",
      query: {
        reportId: null,
        roomId: null,
        name: null,
        grade: null,
        term: null,
        orderBy: "UpdatedTime",
        orderDirection: "DESC",
        pageIndex: 1,
        pageSize: 20,
      },
      options: {
        gradeList: [],
        termList: [],
      },
      currentReport: null,
      tableData: [],
      showWeeklyRecordReportDialog: false,
      weeklyRecordReportDialogTitle: "",
    };
  },
  created() {
    this.options.gradeList = this.$appSetting.globalConfig.gradeList;
    this.options.termList = this.$appSetting.globalConfig.termList;

    let studentId = this.$user.getUsername();
    if (studentId) {
      this.query.studentId = studentId;
      this.loadData();
    }
  },
  methods: {
    loadData() {
      getData(`${this.controllerUrl}/getCurrentStudentWeeklyRecords/quiz`).then((res) => {
        if (res.result && res.code === "200") {
          this.tableData = res.result;
        }
      });
    },
    handleSearch() {
      this.query.pageIndex = 1;
      this.loadData();
    },
    changeSort(sortData) {
      console.log(sortData);
      if (sortData.order) {
        this.query.orderBy = sortData.prop;
        this.query.orderDirection = sortData.order === "ascending" ? "asc" : "desc";
      } else {
        this.query.orderBy = "";
        this.query.orderDirection = "";
      }
    },
    showWeeklyReport(index, row, recordType) {
      this.currentReport = row;
      this.reportRecordType = recordType;
      this.weeklyRecordReportDialogTitle = `The report for ${recordType} weekly records '${this.currentReport.weeklyReport.name}'`;
      this.showWeeklyRecordReportDialog = true;
    },
    showWeeklyReportStatusTagType(index, row) {
      if (row.status !== "Published") {
        return "info";
      }
      return "success";
    },
  },
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 200px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 1.17rem;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
</style>
